import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { extend } from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { ProductResultsContext } from '../ProductResultsContext';
import { StoreSelectorContent } from './StoreSelectorContent';
import '../../styles/store-selector.scss';

const StoreSelector = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { data } = useContext(ProductResultsContext);
  const [displayNearbyStores, setDisplayNearbyStores] = useState(true);
  const { channel, store } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  const { stores } = data?.searchModel?.metadata || {};

  if (!stores) return null;

  const { nearByStores } = stores;

  if (!nearByStores) return null;
  const {
    multiStoreCallback, multiStoreSelection, multiFacet, isFulfillmentRedesignEnabled, storeName
  } = props;

  const caretClassName = classNames('cta__arrow store-selector__caret', {
    'store-selector__caret--closed': !tooltipOpen,
    'store-selector__caret--open': tooltipOpen,
  });

  const storeSelectorClassName = classNames('store-selector', {
    'store-selector--mobile': isMobile,
    'store-selector--margin-bottom': multiFacet,
  });

  const toggleToolTipOpened = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <div className={storeSelectorClassName}>
      {!isMobile && (
        <Tooltip
          channel="desktop"
          closeButton
          placement="right"
          portal
          content={(
            <StoreSelectorContent
              multiStoreCallback={multiStoreCallback}
              multiStoreSelection={multiStoreSelection}
              setNearbyStores={setDisplayNearbyStores}
            />
          )}
        >
          <div className="store-selector" data-testid="store-selector__link">
            {isFulfillmentRedesignEnabled && <span className="store-selector__preamble"> In Store at </span>}
            <span className="store-selector__link">{storeName} {displayNearbyStores && '& nearby stores'} </span>
            {!isFulfillmentRedesignEnabled && <span className={caretClassName} />}
          </div>
        </Tooltip>
      )}
      {isMobile && (
        <>
          {isFulfillmentRedesignEnabled && <span className="store-selector__preamble"> In Store at </span>}
          <span
            className="store-selector--mobile__link"
            data-testid="store-selector--mobile__link"
            role="button"
            tabIndex="-1"
            onClick={toggleToolTipOpened}
          >
            <span className="store-selector__link">{storeName} {displayNearbyStores && '& nearby stores'} </span>
            {!isFulfillmentRedesignEnabled && <span className={caretClassName} />}
          </span>
          {tooltipOpen && (
            <div className="store-selector--mobile__dropdown">
              <span className="instore-sortbtn__dropdown__caret" />
              <span
                className="instore-sortbtn__dropdown__close-button"
                role="button"
                aria-label="store selector dropdown close button"
                tabIndex="-1"
                onClick={toggleToolTipOpened}
              />
              <StoreSelectorContent
                multiStoreCallback={multiStoreCallback}
                multiStoreSelection={multiStoreSelection}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

StoreSelector.displayName = 'StoreSelector';

StoreSelector.dataModel = extend(StoreSelectorContent);

StoreSelector.propTypes = {
  multiStoreCallback: PropTypes.func,
  multiStoreSelection: PropTypes.string,
  multiFacet: PropTypes.bool,
  isFulfillmentRedesignEnabled: PropTypes.bool,
  storeName: PropTypes.string,
};

StoreSelector.defaultProps = {
  multiStoreCallback: () => {
  },
  multiStoreSelection: null,
  multiFacet: false,
  isFulfillmentRedesignEnabled: false,
  storeName: null,
};

export { StoreSelector };
