import React, { useContext, useState } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Checkbox, Col, Row } from '@thd-olt-component-react/core-ui';
import { StandardRefinement } from '../Refinements/StandardRefinement';
import { NumericRangeRefinement } from '../Refinements/NumericRangeRefinement';
import { sortRefinements } from '../../product-results-helpers';
import styles from '../../styles/dimension.scss';

const cx = classNames.bind(styles);

const NumericRangeDimension = ({
  appliedRefinements,
  dimension,
  drawer,
  mini,
  onChange,
  onDisplayData,
  enableMultiStore,
  toggleSwitch,
  onMultiStoreSelectionChange,
  multiStoreSelection,
  multiFacet,
  isActive,
  collapsed,
  metadata,
  selectedRefinements,
}) => {

  const [seeAll, setSeeAll] = useState(false);
  const [collapse, setCollapse] = useState(collapsed);

  const {
    dimensionId,
    label,
    refinements
  } = dimension;

  const handleSeeAll = (event) => {
    event.preventDefault();
    setSeeAll(!seeAll);
  };

  const handleCollapse = (event) => {
    event.preventDefault();
    setCollapse(!collapse);
  };
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  const getRefinementId = (refinement, refinementIndex) => {
    const { refinementKey } = refinement;
    return mini
      ? `${refinementKey}-${refinementIndex}-mini`
      : `${refinementKey}-${refinementIndex}`;
  };

  const handleOnChange = ({
    refinement,
    dimension: dimensionCustomRefinement,
    refinementRange,
  }) => {

    if (onChange) {

      if ((!drawer || isMobile) && !multiFacet) {
        onChange({
          refinement,
          dimension: dimensionCustomRefinement,
        });
      } else {
        onChange({
          multiRefinements: refinementRange,
          dimension: { ...dimension, dimensionId: 'custom_refinement' }
        });
      }
    }

    return null;
  };

  const sortedRefinements = refinements ? [...refinements].sort(sortRefinements) : null;

  const dimensionClassName = cx('dimension', {
    'dimension--mini': mini,
    'dimension--drawer': drawer,
    'dimension--padding': multiFacet
  });

  const dimensionCaretClassName = cx({
    dimension__caret: !collapse,
    'dimension__caret--collapsed': collapse,
  });

  const wrapperClassName = cx({
    'dimension--collapsed': collapse
  });

  const groupClassName = cx('dimension__group dimension__group--numeric-dimension grid', {
    'dimension__group--all': !collapse && seeAll,
    'dimension__group--mini': mini,
    'dimension__group--mini--main-filter-wrapping':
      mini && ((multiFacet && isMobile) || !multiFacet) && label?.toUpperCase() === 'GET IT FAST',
    'dimension__group--mini--main-filter': mini && multiFacet && !isMobile && label?.toUpperCase() === 'GET IT FAST',
    'dimension__group--scroll': !mini,
    'dimension__group--drawer-scroll': drawer && (label?.toUpperCase() !== 'GET IT FAST' || multiFacet)
  });

  const borderClassName = cx('dimension__box', {
    'dimension__box--line': drawer && !collapse && !multiFacet,
    'dimension__box--padding-right': multiFacet,
  });

  const buttonClassName = cx({
    'dimension__see-all': seeAll,
  });

  const dimensionLabelClassName = cx('dimension__label', {
    'dimension__label--padding': !drawer,
    'dimension--active': isActive && drawer && multiFacet
  });

  const shadowClassName = cx({ 'dimension__box--shadow': drawer && refinements?.length > 5 });

  return (
    <div className={dimensionClassName}>
      {!toggleSwitch && (
        <Row className={shadowClassName}>
          {!mini && (
            <Row className={borderClassName}>
              {/* eslint-disable jsx-a11y/anchor-is-valid */}
              <a
                className={borderClassName}
                onClick={handleCollapse}
                href="#"
              >
                <Col fallback="9">
                  <h2 className={dimensionLabelClassName} data-group={label}>{label}</h2>
                </Col>
                <Col fallback="3" className="dimension__caret--align">
                  <img
                    className={dimensionCaretClassName}
                    alt="caret-icon"
                    src="https://assets.thdstatic.com/images/v1/caret-grey.svg"
                    height="15"
                    width="9"
                  />
                </Col>
                {appliedRefinements && (
                  <Col nopadding>
                    <div className="dimension__applied-refinements">
                      {appliedRefinements}
                    </div>
                  </Col>
                )}
              </a>
            </Row>
          )}
          <Row className={wrapperClassName}>
            <NumericRangeRefinement
              dimension={dimension}
              drawer={drawer}
              metadata={metadata}
              onChange={handleOnChange}
              multiFacet={multiFacet}
              isMobile={isMobile}
            />
            <Checkbox.Group
              className={groupClassName}
              key={dimensionId}
              data-instoredisplay="true"
            >
              {
                sortedRefinements && (
                  sortedRefinements
                    .map((refinement, refinementIndex) => {
                      const id = getRefinementId(refinement, refinementIndex);

                      // initialize classNames for current refinement
                      const checkboxClassName = cx('dimension__item', {
                        'dimension__item--hidden': !seeAll && !drawer,
                        'dimension__item--fullwidth': multiFacet,
                      });

                      return (
                        <StandardRefinement
                          checkboxClassName={checkboxClassName}
                          dimension={dimension}
                          id={id}
                          key={id}
                          onChange={handleOnChange}
                          enableMultiStore={enableMultiStore}
                          refinement={refinement}
                          toggleSwitch={toggleSwitch}
                          onMultiStoreSelectionChange={onMultiStoreSelectionChange}
                          multiStoreSelection={multiStoreSelection}
                          onDisplay={onDisplayData.onDisplay}
                          multiFacet={multiFacet}
                        />
                      );
                    })
                )
              }
            </Checkbox.Group>
            {refinements && refinements.length > 5 && !drawer && (
              <Button
                link
                transparent
                className={buttonClassName}
                onClick={handleSeeAll}
              >
                {!seeAll ? '+ View All' : '- View Less'}
              </Button>
            )}
          </Row>
        </Row>
      )}
    </div>
  );
};

NumericRangeDimension.propTypes = {
  appliedRefinements: PropTypes.string,
  collapsed: PropTypes.bool,
  dimension: PropTypes.shape({
    dimensionId: PropTypes.string,
    label: PropTypes.string,
    refinements: PropTypes.arrayOf(
      PropTypes.shape({})
    ),
  }).isRequired,
  drawer: PropTypes.bool,
  mini: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onMultiStoreSelectionChange: PropTypes.func,
  enableMultiStore: PropTypes.bool,
  multiStoreSelection: PropTypes.string,
  onDisplayData: PropTypes.shape({
    onDisplay: PropTypes.string,
    onDisplayChange: PropTypes.func,
    isStoreDisplay: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    itemCount: PropTypes.string
  }),
  toggleSwitch: PropTypes.bool,
  metadata: PropTypes.shape({
    canonicalUrl: PropTypes.string,
  }).isRequired,
  multiFacet: PropTypes.bool,
  isActive: PropTypes.bool,
  selectedRefinements: PropTypes.arrayOf(PropTypes.shape({})),
};

NumericRangeDimension.defaultProps = {
  appliedRefinements: null,
  collapsed: false,
  drawer: false,
  mini: false,
  onDisplayData: {
    onDisplay: false,
    onDisplayChange: null,
    isStoreDisplay: 'false',
    itemCount: null
  },
  toggleSwitch: false,
  enableMultiStore: false,
  onMultiStoreSelectionChange: null,
  multiStoreSelection: null,
  multiFacet: false,
  isActive: false,
  selectedRefinements: null,
};

NumericRangeDimension.displayName = 'NumericRangeDimension';

export { NumericRangeDimension };
