import React from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { AisleBayPod } from '@thd-olt-component-react/aisle-bay';
import { Row, Col } from '@thd-olt-component-react/core-ui';
import { FulfillmentPodShipping, FulfillmentPodStore } from '@thd-olt-component-react/fulfillment';
import { NonBuyableQuoteCenter } from '@thd-olt-component-react/non-buyable-quote-center';
import { OrderSample } from '@thd-olt-component-react/order-sample';
import {
  ProductPod,
  PodHeader,
  ProductATC,
  ProductRating,
  ProductSample,
  ProductBadge, ProductSponsored,
  ProductImage, PodFooter, PodSection,
  ProductCustomOptions, PodSpacer, ProductServiceAddOns, BundleDataModel,
  ProductIdentifier
} from '@thd-olt-component-react/product-pod';
import { ProductSeeSimilarItems } from '@thd-olt-component-react/product-pod-group';
import { Price, PriceClearanceDataModel } from '@thd-olt-component-react/price';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { ProductQuantity } from '@thd-olt-component-react/quantity';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import { useTheme } from '@thd-olt-component-react/theme-provider';

export const CustomProductPodMobile = ({
  itemId,
  storeId,
  position,
  onClick,
  onHover,
  hoverDelay,
  itemRecsIndex,
  hasInStoreFilter,
  impProductPodData,
  samplesLoading,
  showProjectQuantity,
  hasSameDayFilter
}) => {
  const theme = useTheme(CustomProductPodMobile);
  const { productIdentifierTypes, showProductQuantity, showAddToListButton } = theme.props;
  return (
    <ProductPod
      itemId={itemId}
      storeId={storeId}
      onHover={onHover}
      hoverDelay={hoverDelay}
      analyticsData={{ parent: 'plp', position }}
      analyticImpressionData={impProductPodData}
      render={(pod) => (
        <>
          <PodHeader>
            <ProductBadge itemId={pod.itemId} storeId={storeId} />
            <ProductSponsored itemId={pod.itemId} noBackground position={position} />
          </PodHeader>
          <PodSection noPadding>
            <Row>
              <Col fallback={5}>
                <ProductImage itemId={pod.itemId} onClick={onClick} dualImageSwipe />
                <ProductSample itemId={pod.itemId} />
                <ProductCustomOptions itemId={pod.itemId} />
                <PodSpacer padding={['TOP']}>
                  <SuperSkuMiniSwatches
                    itemId={pod.itemId}
                    storeId={storeId}
                    onChange={pod.onChange}
                    onHover={pod.onSSKUHover}
                    componentImpressionData={
                      {
                        name: 'ProductPod',
                        component: 'ProductPod',
                        position: position + 1,
                        type: 'product'
                      }
                    }
                    isImpressionsRequired
                  />
                </PodSpacer>
              </Col>
              <Col fallback={7}>
                <PodSection noPadding columnAlign>
                  <ProductServiceAddOns itemId={pod.itemId} />
                  <PodSpacer minHeight="60px" disable={pod.productSubType === 'HDQC'}>
                    <Price
                      itemId={pod.itemId}
                      large={false}
                      storeId={storeId}
                      showProjectPrice
                      displayEachUom={false}
                      hideForProductTypes={['HDQC']}
                    />
                  </PodSpacer>
                  <ProductRating itemId={pod.itemId} onClick={onClick} />
                  <PodSpacer padding={['TOP', 'BOTTOM']}>
                    <ProductHeader
                      brand="above"
                      itemId={pod.itemId}
                      brandTitleMaxLine={4}
                      disableShopThisCollection
                      onClick={onClick}
                    />
                  </PodSpacer>
                  <PodSpacer padding={['TOP', 'BOTTOM']}>
                    {
                      productIdentifierTypes.map((type) => <ProductIdentifier itemId={pod.itemId} type={type} />)
                    }
                    <ProductIdentifier itemId={pod.itemId} type="model" />
                  </PodSpacer>
                  <PodFooter noPadding>
                    <PodSpacer minHeight="82px" disable={pod.productSubType === 'HDQC' || pod.productSubType === 'SFI'}>
                      <FulfillmentPodStore itemId={pod.itemId} storeId={storeId} hideForChooseYourOptions />
                      <FulfillmentPodShipping
                        itemId={pod.itemId}
                        storeId={storeId}
                        hasSameDayFilter={hasSameDayFilter}
                      />
                    </PodSpacer>
                    <AisleBayPod
                      itemId={pod.itemId}
                      clsRemediation={{
                        placeholders: true,
                        preservePlaceholders: false
                      }}
                      storeId={storeId}
                    />
                    <PodSpacer padding={['TOP', 'BOTTOM']} minHeight="3.95rem">
                      <div className="sui-flex sui-justify-center">
                        <OrderSample
                          disableSampleCall={samplesLoading}
                          itemId={pod.itemId}
                          link
                          showTitle={false}
                        />
                      </div>
                    </PodSpacer>
                    {
                      showProductQuantity
                      && (
                        <PodSpacer minHeight="5rem" padding={['TOP', 'BOTTOM']}>
                          <ProductQuantity
                            itemId={pod.itemId}
                            onQuantityChange={pod.onQuantityUpdate}
                            mediumWidth
                            wideView
                            noRightMargin
                          />
                        </PodSpacer>
                      )
                    }
                    <PodSpacer padding={['TOP', 'BOTTOM']}>
                      <ProductATC
                        itemId={pod.itemId}
                        storeId={storeId}
                        checkGeneric
                        outline
                        hasInStoreFilter={hasInStoreFilter}
                        showProjectQuantity={showProjectQuantity}
                      />
                    </PodSpacer>
                    <ProductSeeSimilarItems
                      itemId={pod.itemId}
                      position={itemRecsIndex}
                      productPodRef={pod.ref}
                      storeId={storeId}
                    />
                    <NonBuyableQuoteCenter itemId={pod.itemId} checkForProductSubType={['HDQC']} />
                    {
                      showAddToListButton
                      && (
                        <PodSpacer padding={['TOP', 'BOTTOM']}>
                          <AddToList itemId={pod.itemId} quantity={pod.quantity} />
                        </PodSpacer>
                      )
                    }
                  </PodFooter>
                </PodSection>
              </Col>
            </Row>
          </PodSection>
        </>
      )}
    />
  );
};

CustomProductPodMobile.displayName = 'CustomProductPodMobile';

CustomProductPodMobile.themeProps = {
  productIdentifierTypes: PropTypes.arrayOf(PropTypes.string),
  showProductQuantity: PropTypes.bool,
  showAddToListButton: PropTypes.bool
};

CustomProductPodMobile.defaultThemeProps = {
  productIdentifierTypes: [],
  showProductQuantity: false,
  showAddToListButton: false
};

CustomProductPodMobile.propTypes = {
  itemId: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  onHover: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  hoverDelay: PropTypes.number.isRequired,
  itemRecsIndex: PropTypes.number.isRequired,
  hasInStoreFilter: PropTypes.bool.isRequired,
  samplesLoading: PropTypes.bool.isRequired,
  impProductPodData: PropTypes.shape({}),
  showProjectQuantity: PropTypes.bool,
  hasSameDayFilter: PropTypes.bool
};

CustomProductPodMobile.defaultProps = {
  impProductPodData: null,
  showProjectQuantity: false,
  hasSameDayFilter: false
};

CustomProductPodMobile.dataModel = extend(
  ProductPod,
  ProductImage,
  Price,
  SuperSkuMiniSwatches,
  ProductServiceAddOns,
  AisleBayPod,
  ProductATC,
  ProductRating,
  ProductSample,
  ProductHeader,
  ProductBadge,
  ProductSponsored,
  FulfillmentPodStore,
  FulfillmentPodShipping,
  ProductCustomOptions,
  NonBuyableQuoteCenter,
  OrderSample,
  PriceClearanceDataModel,
  BundleDataModel
);
