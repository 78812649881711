/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Col, Row } from '@thd-olt-component-react/grid';
import { Radio } from '@thd-olt-component-react/checkbox';
import { Rating } from '@thd-olt-component-react/rating';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ratingsPayload = [{
  name: '5',
  value: 5
}, {
  name: '4 & Up',
  value: 4
}, {
  name: '3 & Up',
  value: 3
}, {
  name: '2 & Up',
  value: 2
}, {
  name: '1 & Up',
  value: 1
}];

const RatingRadioDimension = (props) => {
  const {
    appliedRefinements, dimension, onChange, multiFacet, isActive
  } = props;
  const { label, refinements = [] } = dimension;

  const getAppliedRating = () => {
    const selectedRefinement = refinements.filter((ref) => ref.selected === true)[0];
    const selectedLabel = selectedRefinement?.label;
    const selectedValue = ratingsPayload.filter((rating) => rating.name === selectedLabel)[0];
    return selectedValue?.value || '';
  };
  const whatsChecked = getAppliedRating();
  const [collapsed, setCollapsed] = useState(props.collapsed); // eslint-disable-line react/destructuring-assignment
  const [rating, setRating] = useState('');

  useEffect(() => {
    if (!isActive) {
      setRating('');
    }
  }, [isActive]);

  useEffect(() => {
    setRating(whatsChecked);
  }, [whatsChecked]);

  const handleCollapse = (event) => {
    event.preventDefault();
    setCollapsed(!collapsed);
  };

  const handleRatingChange = (ev) => {
    const selectedValue = ev?.target?.value;
    setRating(parseInt(selectedValue, 10));
    const refinement = refinements.filter((ref) => (ref.label.substr(0, 1) === `${selectedValue}`))[0];
    onChange({ dimension, refinement, radio: true });
  };

  const generateRatingRadios = (radioButtons) => radioButtons.map((radioButton, index) => {
    const { name, value } = radioButton;
    const isChecked = value === rating;
    return (
      <div className="rating-stars" key={index}>
        <input
          checked={isChecked}
          className="rating-stars__input"
          id={`${name}-${value}`}
          name="dimension_review_rating"
          type="radio"
          value={value}
          onClick={handleRatingChange}
        />
        <label className="rating-start__label" htmlFor={`${name}-${value}`}>
          <>
            <Rating className="rating-stars--no-bottom" value={value} />
            {name}
          </>
        </label>
      </div>
    );
  });

  const borderClassName = classNames('dimension__box', {
    'dimension__box--line': !collapsed && !multiFacet,
    'dimension__box--padding-right': multiFacet
  });

  const ratingDrawerClassName = classNames('dimension__rating-drawer', {
    'dimension--collapsed': collapsed
  });

  const dimensionClasses = classNames(
    'dimension',
    'dimension--drawer',
    {
      'dimension--padding': multiFacet
    });

  const dimensionLabelClassName = classNames('dimension__label', {
    'dimension--active': isActive && multiFacet
  });

  return (
    <div className={dimensionClasses}>
      <Row>
        <a className={borderClassName} href="#" onClick={handleCollapse}>
          <Col fallback="9">
            <h2 className={dimensionLabelClassName} data-group={label}>{label}</h2>
          </Col>
          <Col fallback="3" className="dimension__caret--align">
            <img
              className={collapsed ? 'dimension__caret--collapsed' : 'dimension__caret'}
              alt="caret-icon"
              src="https://assets.thdstatic.com/images/v1/caret-grey.svg"
              height="15"
              width="9"
            />
          </Col>
          {appliedRefinements && (
            <Col nopadding>
              <div className="dimension__applied-refinements">
                {appliedRefinements === '5' ? 'Best Rated' : appliedRefinements}
              </div>
            </Col>
          )}
        </a>
      </Row>
      <div className={ratingDrawerClassName}>
        <Radio.Group>{generateRatingRadios(ratingsPayload)}</Radio.Group>
      </div>
    </div>
  );
};

RatingRadioDimension.propTypes = {
  appliedRefinements: PropTypes.string,
  collapsed: PropTypes.bool,
  dimension: PropTypes.shape({
    dimensionId: PropTypes.string,
    label: PropTypes.string,
    refinements: PropTypes.arrayOf(
      PropTypes.shape({})
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  multiFacet: PropTypes.bool,
  isActive: PropTypes.bool,
};

RatingRadioDimension.defaultProps = {
  appliedRefinements: null,
  collapsed: false,
  multiFacet: false,
  isActive: false,
};

RatingRadioDimension.displayName = 'RatingRadioDimension';

export { RatingRadioDimension };
