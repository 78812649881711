import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Col, Image } from '@thd-olt-component-react/core-ui';
import '../../styles/refinement.scss';
import PropTypes from 'prop-types';

const ColorSwatchRefinement = ({
  active,
  dimension,
  drawer,
  multiFacet,
  onChange,
  onDisplay,
  refinement,
  refinementClassName,
}) => {

  const {
    selected, imgUrl, url, label
  } = refinement;

  const isSelected = (!multiFacet && !!selected) || (multiFacet && active);

  const constructImageURL = () => {
    const COLOR_SWATCH_ASSET_PATH = 'https://assets.thdstatic.com/images/v1';
    const SIZE = '100';
    if (Number(imgUrl) === 1 || !imgUrl) {
      return `https://images.homedepot-static.com/catalog/productImages/No_Image_${SIZE}.jpg`;
    }
    return `${COLOR_SWATCH_ASSET_PATH}/${imgUrl}`;
  };

  const swatchImageSrc = constructImageURL();

  const handleOnChangeLink = useCallback((event) => {
    event.preventDefault();
    if (onChange) {
      onChange({
        refinement, dimension, onDisplay
      });
    }
  }, [onChange, refinement, dimension, onDisplay]);

  const selectedRefinementClassName = classNames('refinement__color-swatch--link-wrapper', {
    'refinement__color-swatch--selected': isSelected,
  });

  return (
    <>
      <Col fallback="12" className={refinementClassName}>
        <div className="refinement__color-swatch--wrapper">
          <a
            className={selectedRefinementClassName}
            type="button"
            href={url}
            onClick={(event) => handleOnChangeLink(event)}
          >
            <div className="refinement__color-swatch--image-wrapper">
              <Image
                alt={label}
                className="refinement__color-swatch--image"
                id={`color-swatch-${imgUrl}`}
                src={swatchImageSrc}
                lazy
                height="25"
                width="25"
              />
            </div>
            <span className="refinement__color-swatch--label">{label}</span>

          </a>
        </div>
      </Col>
    </>
  );
};

ColorSwatchRefinement.propTypes = {
  active: PropTypes.bool,
  dimension: PropTypes.shape({}),
  drawer: PropTypes.bool,
  multiFacet: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDisplay: PropTypes.bool,
  refinement: PropTypes.shape({
    label: PropTypes.string,
    selected: PropTypes.bool,
    url: PropTypes.string,
    imgUrl: PropTypes.string,
  }),
  refinementClassName: PropTypes.string,
};

ColorSwatchRefinement.defaultProps = {
  active: null,
  dimension: null,
  drawer: false,
  multiFacet: null,
  onDisplay: false,
  refinement: null,
  refinementClassName: null,
};

ColorSwatchRefinement.displayName = 'ColorSwatchRefinement';

export { ColorSwatchRefinement };
