import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import classNames from 'classnames';
import { ProductResultsContext } from '../ProductResultsContext';
import { NearbyStores } from './NearbyStores';
import { ChangeStore } from './ChangeStore';
import '../../styles/store-selector.scss';

const StoreSelectorContent = React.forwardRef((props, ref) => {
  const [changeStore, setChangeStore] = useState(false);

  const { data } = useContext(ProductResultsContext);
  const { stores } = data?.searchModel?.metadata || {};
  const { channel } = useContext(ExperienceContext);

  if (!stores) return null;

  const isMobile = channel === 'mobile';

  const onChangeClick = () => {
    setChangeStore(!changeStore);
  };

  const {
    storeId,
    storeName,
    phone,
    address,
    nearByStores = []
  } = stores;

  const storeSearch = [
    {
      storeId,
      storeName,
      phone,
      address
    }
  ];

  nearByStores.forEach((store) => {
    storeSearch.push(store);
  });

  const { multiStoreCallback, multiStoreSelection, setNearbyStores } = props;

  const contentClasses = classNames('store-selector__content', {
    'store-selector__content--mobile': isMobile
  });

  return (
    <div className={contentClasses} ref={ref}>
      {!changeStore && (
        <NearbyStores
          stores={storeSearch}
          onChangeClick={onChangeClick}
          multiStoreCallback={multiStoreCallback}
          setNearbyStores={setNearbyStores}
        />
      )}
      {changeStore && (
        <ChangeStore postalCode={address.postalCode} stores={storeSearch} onChangeClick={onChangeClick} />
      )}
    </div>
  );
});

StoreSelectorContent.displayName = 'StoreSelectorContent';

StoreSelectorContent.dataModel = extend(ChangeStore);

StoreSelectorContent.propTypes = {
  multiStoreCallback: PropTypes.func,
  multiStoreSelection: PropTypes.string,
  setNearbyStores: PropTypes.func,
};

StoreSelectorContent.defaultProps = {
  multiStoreCallback: () => {},
  multiStoreSelection: null,
  setNearbyStores: () => {},
};

export { StoreSelectorContent };
