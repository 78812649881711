/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Row } from '@thd-olt-component-react/grid';
import { VisualRefinement } from '../Refinements/VisualRefinement';

import '../../styles/dimension.scss';

const VisualDimension = ({
  collapsed,
  appliedRefinements,
  dimension,
  drawer,
  filter,
  mini,
  onChange,
  multiFacet
}) => {

  const [seeAll, setSeeAll] = useState(false);
  const [collapse, setCollapse] = useState(!!drawer);
  const [filterInput, setFilterInput] = useState('');

  const onRefinementFilter = (event) => {
    setFilterInput(event.target.value);
  };

  const handleSeeAll = (event) => {
    event.preventDefault();
    setSeeAll(!seeAll);
  };

  const handleCollapse = (event) => {
    event.preventDefault();
    setCollapse(!collapse);
  };

  const getRefinementId = (refinement, refinementIndex) => {
    const { refinementKey } = refinement;
    return `${refinementKey}-${refinementIndex}`;
  };

  const {
    dimensionId,
    label,
    refinements
  } = dimension;

  const leftNavFilter = !seeAll && !drawer && !mini;
  const topFilter = !seeAll && !drawer && mini;
  const visualRefinementMax = topFilter ? 5 : 3;

  const dimensionClassName = classNames('dimension', {
    'dimension--mini': mini,
    'dimension--drawer': drawer,
    'dimension--padding': multiFacet
  });

  const wrapperClassName = classNames({
    'dimension--collapsed': collapse
  });

  const groupClassName = classNames('dimension__visual-refinement', {
    'dimension__group--all': !collapse && seeAll,
    'dimension__group--mini': mini,
    'dimension__group--scroll': !mini,
    'dimension__group--drawer-scroll': drawer
  });

  const borderClassName = classNames('dimension__box', {
    'dimension__box--line': drawer && !collapse && !multiFacet,
    'dimension__box--padding-right': multiFacet
  });

  const filterClassName = classNames('dimension__filter', {
    'dimension__filter--align': drawer,
    'dimension__filter--margin-small': multiFacet
  });

  const dimensionLabelClassName = classNames('dimension__label', {
    'dimension__label--padding': !drawer
  });

  const shadowClassName = classNames({ 'dimension__box--shadow': drawer && refinements?.length > 5 });

  const visualRefinementClassname = classNames('refinement__card-container', {
    'refinement__card--hidden': leftNavFilter,
    'refinement__mini-card--hidden': topFilter
  });

  return (
    <div className={dimensionClassName}>
      <Row className={shadowClassName}>
        {!mini && (
          <Row>
            <a className={borderClassName} href="#" onClick={handleCollapse}>
              <Col fallback="9">
                <h2 className={dimensionLabelClassName} data-group={label}>{label}</h2>
              </Col>
              <Col fallback="3" className="dimension__caret--align">
                <img
                  className={collapse ? 'dimension__caret--collapsed' : 'dimension__caret'}
                  alt="caret-icon"
                  src="https://assets.thdstatic.com/images/v1/caret-grey.svg"
                  height="15"
                  width="9"
                />
              </Col>
              {appliedRefinements && (
                <Col nopadding>
                  <div className="dimension__applied-refinements">
                    {appliedRefinements}
                  </div>
                </Col>
              )}
            </a>
          </Row>
        )}
        <Row className={wrapperClassName}>
          {filter && (
            <input
              placeholder="Search"
              className={filterClassName}
              value={filterInput}
              type="text"
              onChange={onRefinementFilter}
            />
          )}
          {
            <div
              className={groupClassName}
              key={dimensionId}
              data-instoredisplay="true"
              data-testid="visual-refinement-card-deck"
            >
              {
                refinements && refinements
                  .filter((ref) => (ref.label || '').toUpperCase().indexOf(filterInput.toUpperCase()) !== -1)
                  .map((refinement, refinementIndex) => {
                    const refinementId = getRefinementId(refinement, refinementIndex);
                    if (!seeAll && refinementIndex > visualRefinementMax && !drawer) {
                      return '';
                    }
                    return (
                      <VisualRefinement
                        visualRefinementClassname={visualRefinementClassname}
                        dimension={dimension}
                        key={refinementId}
                        onChange={onChange}
                        refinement={refinement}
                        drawer={drawer}
                      />
                    );
                  })
              }
            </div>
          }
          {refinements && refinements.length > 4 && !drawer && (
            <a
              className="dimension__see-all"
              href="#"
              onClick={handleSeeAll}
            >
              {!seeAll ? '+ View All' : '- View Less'}
            </a>
          )}
        </Row>
      </Row>
    </div>
  );
};

VisualDimension.propTypes = {
  appliedRefinements: PropTypes.string,
  collapsed: PropTypes.bool,
  dimension: PropTypes.shape({
    dimensionId: PropTypes.string,
    label: PropTypes.string,
    refinements: PropTypes.arrayOf(
      PropTypes.shape({})
    ),
  }).isRequired,
  drawer: PropTypes.bool,
  filter: PropTypes.bool,
  mini: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  multiFacet: PropTypes.bool,
};

VisualDimension.defaultProps = {
  appliedRefinements: null,
  collapsed: false,
  drawer: false,
  filter: false,
  mini: false,
  multiFacet: false,
};

VisualDimension.displayName = 'VisualDimension';

export { VisualDimension };
