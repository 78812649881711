import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '@thd-olt-component-react/pagination';
import { ProductResultsContext } from './ProductResultsContext';

import '../styles/results-pagination.scss';

const ResultsPagination = (props) => {
  const { data } = useContext(ProductResultsContext);

  const { onPageChange, pageSize, path } = props;

  const { products = [], searchReport } = data?.searchModel || {};
  if (!products.length || !searchReport) return null;

  const totalProducts = searchReport?.totalProducts > 696
    ? 696
    : searchReport?.totalProducts;

  const actualProductCount = searchReport?.totalProducts;
  const startIndex = searchReport?.startIndex || 0;

  const currentPage = Math.round(startIndex / pageSize) + 1;
  const totalPages = Math.ceil(totalProducts / pageSize);
  const pageStart = (currentPage - 1) * pageSize + 1;
  const pageEnd = Math.min(currentPage * pageSize, totalProducts);
  const pageRange = `${pageStart}-${pageEnd}`;

  if (Number.isNaN(totalPages)
  || Number.isNaN(pageSize)
  || totalPages === 1
  || totalPages === Infinity) return null;

  const onChangeWrapper = (page, url) => {
    if (onPageChange) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('resetImpressionProvider');
      onPageChange({ page, searchReport, url });
    }
  };

  return (
    <>
      <Pagination
        currentPage={currentPage}
        maxNumericBlocks={5}
        onPageChanged={onChangeWrapper}
        showArrows
        totalPages={totalPages}
        pageSize={pageSize}
        href={path}
      />
      <div className="results-pagination__counts">
        Showing
        <span className="results-pagination__counts--number"> {pageRange} </span>
        of
        <span className="results-pagination__counts--number"> {actualProductCount} </span>
        results.
      </div>
    </>
  );
};

ResultsPagination.defaultProps = {
  pageSize: 24,
  path: ''
};

ResultsPagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  path: PropTypes.string
};

ResultsPagination.displayName = 'ResultsPagination';

export { ResultsPagination };
