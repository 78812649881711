import React, { useState, useEffect } from 'react';
import { string, bool, arrayOf as propArrayOf } from 'prop-types';
import classNames from 'classnames';
import { Input } from '@thd-olt-component-react/input';
import { Loader } from '@thd-olt-component-react/loader';
import { Button } from '@thd-olt-component-react/button';
import { Alert } from '@thd-olt-component-react/alert';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withLayoutGroup } from '@thd-olt-component-react/layout-manager';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import {
  useDataModel,
  params,
  arrayOf,
  string as stringType,
} from '@thd-nucleus/data-sources';
import { HdhomeSignupPlaceholder } from './HdhomeSignupPlaceholder';
import styles from './email-signup.module.scss';

const PIP_SOURCE_ID = '1381';
const PEP_SOURCE_ID = '1380';

const PRODUCT_COMPONENT_NAME = 'ProductPage';
const PEP_COMPONENT_NAME = 'PepPage';
const BROWSE_COMPONENT_NAME = 'BrowseAndSearchPage';

export const getSourceId = (path) => {
  const namesWithoutIndex = path.map((name) => name.split('[')[0]);

  const index = namesWithoutIndex.findIndex((componentName) => {
    return componentName === PRODUCT_COMPONENT_NAME
      || componentName === PEP_COMPONENT_NAME
      || componentName === BROWSE_COMPONENT_NAME;
  });

  const parentPage = namesWithoutIndex[index];

  switch (parentPage) {
  case (PRODUCT_COMPONENT_NAME): {
    return PIP_SOURCE_ID;
  }
  case (BROWSE_COMPONENT_NAME):
  case (PEP_COMPONENT_NAME): {
    return PEP_SOURCE_ID;
  }
  default: {
    return null;
  }
  }
};

const HdhomeSignupComponent = (props) => {

  const [state, setState] = useState({
    isFormSubmissionActive: false,
    successState: false,
    emailValue: '',
    formIsReady: false,
    errorState: false,
    errorValue: ''
  });
  const {
    isFormSubmissionActive,
    successState,
    emailValue,
    formIsReady,
    errorState,
    errorValue
  } = state;

  const {
    formSubmission,
    campaignId,
    subscriptionType
  } = props;

  const handleEmailChange = (val) => {
    setState((previousState) => {
      return {
        ...previousState,
        emailValue: val,
        errorState: false
      };
    });
  };

  const emailValidation = (text) => {
    const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line max-len
    return emailRE.test(String(text).toLowerCase());
  };

  const options = {
    variables: {
      sourceId: campaignId,
      subscriptionType: subscriptionType.length ? subscriptionType : ['Promotions']
    }
  };

  const [submitEmail, preferencesEmailResponse] = useDataModel('preferencesEmail', options);
  const { responseStatus } = preferencesEmailResponse?.data?.preferencesEmail || {};

  useEffect(() => {
    if (responseStatus === 'Success') {
      setState((previousState) => {
        return {
          ...previousState,
          isFormSubmissionActive: false,
          successState: true
        };
      });
    }
    if (responseStatus === 'Error occurred') {
      setState((previousState) => {
        return {
          ...previousState,
          isFormSubmissionActive: false,
          errorState: true,
          errorValue: responseStatus + ', please try again'
        };
      });
    }
  }, [responseStatus]);

  const onValidate = () => {
    setState((previousState) => {
      return {
        ...previousState,
        formIsReady: true
      };
    });
  };

  const onValidationError = () => {
    setState((previousState) => {
      return {
        ...previousState,
        formIsReady: false
      };
    });
  };

  const submitForm = (evt) => {
    evt.preventDefault();
    if (formIsReady) {
      setState((previousState) => {
        return {
          ...previousState,
          isFormSubmissionActive: true,
          errorState: false,
          successState: false
        };
      });
      submitEmail({
        variables: {
          emailId: emailValue,
        }
      });
    } else {
      setState((previousState) => {
        return {
          ...previousState,
          isFormSubmissionActive: false,
          errorState: true,
          errorValue: 'Please provide your email address, formatted as you@domain.com.'
        };
      });
    }
  };

  const buttonIcon = isFormSubmissionActive ? <Loader active bttn /> : <span>Sign Up</span>;
  const buttonClass = classNames({
    [styles['email-form__button']]: true,
    [styles['email-form__button--error-message']]: emailValue !== '',
    [styles['email-form__button--success-message']]: formIsReady
  });

  const title = 'Get 10% off your purchase';
  const linkText = 'Sign Up';
  const link = 'http://pages.homedepot.com/styleanddecor';

  return (
    <div className={styles['email-signup']}>
      <div className={styles['hdhome-catalog-promo__item']}>
        <div className={styles['hdhome-catalog-promo__copy']}>
          <span className={styles['hdhome-catalog-promo__email-icon']} />
          <span className={styles['hdhome-catalog-promo__title-header']}>
            <h3 className="hdhome-catalog-promo__title">{title}</h3>
          </span>
          <span className={styles['hdhome-catalog-promo__content']}>
            <p className={styles['hdhome-catalog-promo__item-p']}>
              <span className={styles['hdhome-catalog-promo__item-span']}>
                Sign up for emails &amp; save 10% on select home decor.
              </span>
              <span className={styles['hdhome-catalog-promo__item-span']}> Valid on select items.</span>
            </p>
          </span>
        </div>
        <div className={styles['hdhome-catalog-promo__action']}>
          {!formSubmission && (
            <div className="hdhome-catalog-promo__link">
              <Button outline onClick={(evt) => { window.location = link; }}>{linkText}</Button>
            </div>
          )}
          {formSubmission && (successState ? (
            <Alert
              type="success"
              message="Thanks for signing up. A promo code will arrive in your inbox shortly."
              inline
            />
          ) : (
            <form className={styles['email-form']} name="emailPromoBox" onSubmit={submitForm}>
              <span className={styles['email-form__input']}>
                <Input
                  errorText="Enter a valid email"
                  header="Enter Your Email:"
                  placeholder="yourname@email.com"
                  value={emailValue}
                  onChange={handleEmailChange}
                  onValidate={onValidate}
                  onValidationError={onValidationError}
                  validation={emailValidation}
                />
              </span>
              <span className={buttonClass}>
                <Button>{ buttonIcon }</Button>
              </span>
              { errorState && <span className={styles['email-form__error-message']}>{errorValue}</span> }
            </form>
          ))}
        </div>
      </div>
    </div>
  );
};

HdhomeSignupComponent.propTypes = {
  campaignId: string,
  subscriptionType: propArrayOf(string),
  formSubmission: bool
};

HdhomeSignupComponent.defaultProps = {
  campaignId: null,
  subscriptionType: [],
  formSubmission: false
};

HdhomeSignupComponent.displayName = 'HdhomeSignup';
HdhomeSignupComponent.dataModel = {
  preferencesEmail: params({
    emailId: stringType().isRequired(),
    sourceId: stringType(),
    subscriptionType: arrayOf(stringType())
  }).mutation().shape({
    responseStatus: stringType().isRequired()
  })
};

const HydratedComponent = withHydrator({
  delay: 2000,
  id: 'hdhome-catalog-hydrator',
  placeholder: (
    <HdhomeSignupPlaceholder
      color="#E0E0E0"
      showLoadingAnimation
    />
  ),
  scrollBuffer: 750,
}, HdhomeSignupComponent);

const DynamicComponent = withDynamicComponent(HydratedComponent);
const HdhomeSignup = withLayoutGroup({
  name: 'email-promo'
}, DynamicComponent);

export { HdhomeSignup };