/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Row } from '@thd-olt-component-react/grid';
import { Button } from '@thd-olt-component-react/button';
import { StandardDimension } from '../Dimensions/StandardDimension';
import { VisualDimension } from '../Dimensions/VisualDimension';
import { FILTER_LABEL, FILTER_SORT_LABEL, getRefinementsByDimension } from '../../product-results-helpers';

class TopFilters extends Component {

  constructor(props) {
    super(props);
    const { multiFacet, isMobile } = props;
    this.state = {
      collapsed: true,
      currentDimension: null
    };

    this.sortFilter = {
      dimensionId: 'sort-filter',
      label: multiFacet && !isMobile ? FILTER_LABEL : FILTER_SORT_LABEL
    };
  }

  componentDidMount() {
    window.addEventListener('popstate', this.onPopstate);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onPopstate);
  }

  getRefinementId = (refinement, refinementIndex) => {
    const { refinementKey } = refinement;
    return `${refinementKey}-${refinementIndex}`;
  };

  handleSeeAll = (event) => {
    event.preventDefault();
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed
    }));
  };

  onRefinementChange = (args) => {
    const { onChange } = this.props;
    this.setState({ currentDimension: null });
    onChange({ ...args });
  };

  getDimension = () => {
    const { dimensions = [] } = this.props;
    const { currentDimension } = this.state;

    if (!currentDimension) {
      return null;
    }

    const dimension = dimensions.find((dim) => dim.label === currentDimension.label);
    if (!dimension) {
      return null;
    }

    const { isVisualDimension } = dimension;

    const {
      enableMultiStore,
      onMultiStoreSelectionChange,
      multiStoreSelection,
      multiFacet,
      onDisplayData,
      isMobile,
      appliedDimensions,
    } = this.props;
    const refinements = getRefinementsByDimension(appliedDimensions, dimension);

    if (isVisualDimension) {
      return (
        <VisualDimension
          mini
          key={dimension.dimensionId}
          onChange={this.onRefinementChange}
          dimension={dimension}
        />
      );
    }

    return (
      <div className="top-filters__dimension">
        <StandardDimension
          key={dimension.dimensionId}
          dimension={dimension}
          onChange={this.onRefinementChange}
          mini
          enableMultiStore={enableMultiStore}
          onMultiStoreSelectionChange={onMultiStoreSelectionChange}
          multiStoreSelection={multiStoreSelection}
          multiFacet={multiFacet}
          onDisplayData={onDisplayData}
          isMobile={isMobile}
          selectedRefinements={refinements}
        />
        <button
          type="button"
          className="top-filters__dimension__edge"
          onClick={this.closeDrawer}
        />
      </div>
    );
  };

  openDrawer = (dim) => {
    this.setState({ currentDimension: dim });
  };

  closeDrawer = () => {
    this.setState({ currentDimension: null });
  };

  handleToggleDrawer = () => {
    const { toggleDrawer } = this.props;
    if (toggleDrawer) {
      toggleDrawer();
    }
  };

  onPopstate = () => {
    const { handleCloseDrawer, isOpen } = this.props;
    if (isOpen) handleCloseDrawer();
  };

  openLabel = (event, { dimensions, label }) => {
    event.preventDefault();
    this.setState({ collapsed: true });
    const { currentDimension } = this.state;
    if ([FILTER_LABEL, FILTER_SORT_LABEL].includes(label)) {
      if (typeof window !== 'undefined') {
        window.history.pushState({ page: 'FILTER_SORT_DRAWER', scrollY: window.scrollY }, null, '');
      }
      this.handleToggleDrawer();
    } else if (currentDimension && currentDimension.label === label) {
      this.closeDrawer();
    } else {
      const dim = dimensions.find((dimension) => dimension.label === label);
      this.openDrawer(dim);
    }
  };

  getTopDimensions = () => {
    const { dimensions = [], suppressFilter, onDisplayData: { isStoreDisplay } } = this.props;

    let getItFast = null;
    let availability = null;
    let reviewRating = null;
    let price = null;
    dimensions
      .forEach((dimension) => {
        let dimensionUpperCase = dimension.label.toUpperCase();
        if (dimensionUpperCase === 'GET IT FAST') {
          getItFast = dimension;
        } else if (dimensionUpperCase === 'AVAILABILITY' && isStoreDisplay === 'false') {
          availability = dimension;
        } else if (dimensionUpperCase === 'REVIEW RATING') {
          reviewRating = dimension;
        } else if (dimensionUpperCase === 'PRICE') {
          price = dimension;
        }
      });
    let topDimensions = [getItFast, availability, reviewRating, price].filter((dimension) => dimension != null);

    return topDimensions.length > 0 ? topDimensions : null;
  };

  getLabelByDimension = ({ dimensionId, label }) => {
    const { currentDimension } = this.state;
    const { dimensions = [] } = this.props;
    const colClassName = classNames('top-filters__col', {
      'top-filters__col--bold': (currentDimension && label === currentDimension.label),
    });
    const caretClassName = classNames('top-filters__caret ', {
      'top-filters__caret--open': (currentDimension && label === currentDimension.label),
    });
    const linkClassName = classNames('top-filters__link', {
      'top-filters__link--blue top-filters__box': label === this.sortFilter.label
    });

    return (
      <div
        key={dimensionId}
        className={colClassName}
      >
        <Button
          className={linkClassName}
          link
          tag="a"
          onClick={(event) => this.openLabel(event, { label, dimensions })}
        >
          <span className="top-filters__label">
            {dimensionId === this.sortFilter.dimensionId ? this.sortFilter.label : label}
          </span>
          {dimensionId !== this.sortFilter.dimensionId
            && (
              <img
                alt="open arrow"
                className={caretClassName}
                src="https://assets.thdstatic.com/images/v1/caret-orange.svg"
                height="15"
                width="9"
              />
            )}
        </Button>
      </div>
    );
  }

  getLabels = ({ filterDimensions = [] }) => {
    if (filterDimensions && filterDimensions.length) {
      filterDimensions.unshift(this.sortFilter);
      let topFilters = filterDimensions;
      return (
        <div className="top-filters__labels-container">
          {
            topFilters.map((dimension) => {
              return this.getLabelByDimension(dimension);
            })
          }
        </div>
      );
    }
    const sortFilterClasses = classNames(
      'top-filters__link',
      'top-filters__link--blue',
      'top-filters__box'
    );

    return (
      <div className="top-filters__col">
        <Button
          className={sortFilterClasses}
          link
          tag="a"
          onClick={(event) => this.openLabel(event, this.sortFilter)}
        >
          <span className="top-filters__label">
            {this.sortFilter.label}
          </span>
        </Button>
      </div>
    );
  };

  render() {
    const {
      dimensions,
      maxDimension
    } = this.props;
    let filterDimensions = [];
    if (dimensions.length === 0) return null;
    let topDimensions = this.getTopDimensions();
    if (topDimensions && topDimensions.length) {
      filterDimensions = topDimensions.slice(0, maxDimension);
    }
    return (
      <div className="grid top-filters__wrapper">
        <Col className="top-filters--background">
          {this.getLabels({ filterDimensions })}
        </Col>
        <Row>
          {this.getDimension()}
        </Row>
      </div>
    );
  }
}

TopFilters.propTypes = {
  suppressFilter: PropTypes.arrayOf(PropTypes.string),
  dimensions: PropTypes.arrayOf(
    PropTypes.shape({})
  ).isRequired,
  maxDimension: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onDisplayData: PropTypes.shape({
    isStoreDisplay: PropTypes.string,
    itemCount: PropTypes.string,
    onDisplay: PropTypes.string,
    onDisplayChange: PropTypes.func
  }),
  toggleDrawer: PropTypes.func.isRequired,
  handleCloseDrawer: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  enableMultiStore: PropTypes.bool,
  multiStoreSelection: PropTypes.string,
  onMultiStoreSelectionChange: PropTypes.func,
  multiFacet: PropTypes.bool,
  isMobile: PropTypes.bool,
  appliedDimensions: PropTypes.arrayOf(PropTypes.shape({})),
};

TopFilters.defaultProps = {
  suppressFilter: [],
  maxDimension: 0,
  enableMultiStore: false,
  multiStoreSelection: null,
  onMultiStoreSelectionChange: null,
  multiFacet: false,
  onDisplayData: {
    onDisplay: 'false',
    onDisplayChange: null,
    isStoreDisplay: 'false',
    itemCount: null
  },
  isMobile: false,
  appliedDimensions: null
};

export {
  TopFilters
};
