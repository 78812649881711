/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { publish } from '../publisher';
import { ProductResultsContext } from './ProductResultsContext';
import { AppliedRefinement } from './Refinements/AppliedRefinement';
import { getClearAllWithSort, getAppliedRefinements } from '../product-results-helpers';
import '../styles/results-applied.scss';

const ResultsApplied = ({
  onAppliedChange,
  onDisplay,
  onDisplayChange,
  viewPrimaryFilters,
  isFulfillmentRedesignEnabled,
  hideResultsCount
}) => {
  const { data, loading } = useContext(ProductResultsContext);
  const [cancelClick, setCancelClick] = useState(false);

  if (!data) return null;

  const handleOnDisplayChange = () => {
    onDisplayChange({ useStoreFilter: 'false', multiStoreIds: [] });
  };

  const { appliedDimensions = [], searchReport, metadata } = data?.searchModel || {};

  const { totalProducts } = searchReport || {};

  let appliedRefinements = getAppliedRefinements(appliedDimensions, isFulfillmentRedesignEnabled);

  if (onDisplay === 'true') {
    appliedRefinements.push({
      dimensionName: 'Get It Fast',
      refinementLabel: 'On Display',
      refinementKey: ''
    });
  }

  const handleClearAllClick = () => {
    const url = getClearAllWithSort({ metadata, searchReport });
    const refinement = {
      url,
      clear: true
    };
    if (onAppliedChange) {
      onAppliedChange({ refinement, onDisplay: 'false' });
    }
    // trigger below event for setting DDE.filters as empty
    publish('change-filters-refinements-clear-all', refinement);
  };

  const resultsAppliedLabelClasses = classNames({
    'results-applied__label': !viewPrimaryFilters,
    'results-applied__primary-filter-label': viewPrimaryFilters
  });

  // Removal of filters : upon click of 'x', trigger below event
  if (cancelClick && onAppliedChange && !loading) {
    if (appliedDimensions && Object.keys(appliedDimensions).length > 0) {
      publish('change-filters-refinements-shortings', appliedDimensions);
    } else {
      handleClearAllClick(); // applied and removed 1st filter
    }
    setCancelClick(false);
  }

  const handleFiltersChipChange = (props) => {
    setCancelClick(true);
    onAppliedChange(props);
  };

  return (
    <div className="results-applied" data-component="ResultsApplied">
      {!hideResultsCount && (totalProducts || totalProducts === 0) && (
        <span className={resultsAppliedLabelClasses}>{totalProducts.toLocaleString()} Results</span>
      )}
      {!viewPrimaryFilters && appliedRefinements.map((refinement, index) => {
        const { dimensionName, refinementLabel, refinementKey, url } = refinement;

        const refinementLabelShown = (refinementLabel === 'Pick Up Today')
          ? 'In Stock at Store Today'
          : refinementLabel;

        return (
          <AppliedRefinement
            key={index}
            index={index}
            deselectUrl={url}
            dimensionName={dimensionName}
            label={refinementLabelShown}
            refinementKey={refinementKey}
            onChange={(refinementLabel === 'On Display') ? handleOnDisplayChange : handleFiltersChipChange}
            onDisplay={onDisplay}
          />
        );
      })}
      {!viewPrimaryFilters && appliedRefinements.length > 1 && (
        <a
          className="results-applied__clear-all"
          onClick={handleClearAllClick}
          role="button"
          tabIndex={0}
        >
          Clear All
        </a>
      )}
    </div>
  );
};

ResultsApplied.propTypes = {
  onAppliedChange: PropTypes.func.isRequired,
  onDisplay: PropTypes.string,
  onDisplayChange: PropTypes.func,
  viewPrimaryFilters: PropTypes.bool,
  hideResultsCount: PropTypes.bool,
  isFulfillmentRedesignEnabled: PropTypes.bool,
};

ResultsApplied.defaultProps = {
  onDisplay: 'false',
  onDisplayChange: null,
  viewPrimaryFilters: false,
  hideResultsCount: false,
  isFulfillmentRedesignEnabled: false,
};

ResultsApplied.displayName = 'ResultsApplied';

export { ResultsApplied };
