import React from 'react';
import classnames from 'classnames';
import { LoadingButton, IconButton } from '@one-thd/sui-atomic-components';
import { Ruler, ArrowForward } from '@one-thd/sui-icons';
import { useFitCompatibility } from './FitCompatibilityProvider';
import { FitCompatibilityWidgetMini } from './FitCompatibilityWidgetMini';

export const FitCompatibilityWidget = () => {
  const {
    isLoading,
    header,
    subtext,
    canHaveArrow,
    isPip,
    isPlp,
    isMini,
    setDrawerOpen
  } = useFitCompatibility();

  if (isMini) return <FitCompatibilityWidgetMini />;

  { /* eslint-disable-next-line tailwindcss/no-arbitrary-value, max-len */ }
  const containerClassNames = classnames('sui-flex sui-justify-between sui-bg-primary sui-gap-2 sui-p-3', {
    'sm:sui-flex-wrap sui-max-w-[50.6rem] lg:sui-max-w-[73rem]': isPlp,
    'sui-flex-wrap sui-flex-col sm:sui-flex-row': isPip
  });

  const headerClassNames = classnames('sui-leading-tight sui-font-bold sui-truncate sui-text-wrap', {
    'sui-text-base sm:sui-text-2xl': isPlp,
    'sui-text-2xl': isPip
  });

  const subtextClassNames = classnames('sui-leading-normal sui-truncate sui-text-wrap', {
    'sui-text-xs sm:sui-text-base': isPlp,
    'sui-text-base': isPip
  });

  const ctaClassNames = classnames('sui-flex sui-justify-end md:sui-pl-10', {
    'md:sui-self-center md:sui-grow-0 sui-grow': isPlp,
    'sui-self-center sui-w-full sm:sui-w-fit': isPip
  });

  { /* eslint-disable-next-line tailwindcss/no-custom-classname */ }
  const arrowClassNames = classnames('sui-contents', {
    'sm:sui-hidden': canHaveArrow,
    '!sui-hidden': !canHaveArrow
  });

  { /* eslint-disable-next-line tailwindcss/no-custom-classname */ }
  const buttonClassNames = classnames('sui-hidden', {
    'sm:sui-contents sm:sui-w-32 sui-w-full': canHaveArrow,
    '!sui-contents': !canHaveArrow
  });

  return (
    <div className={containerClassNames}>
      <div className="sui-grid sui-auto-cols-3 sui-gap-x-2 sui-justify-start">
        <span className="sui-row-span-2 sui-mt-0">
          <Ruler data-testid="fit-icon" size="large" />
        </span>
        <div className="sui-col-start-2 sui-truncate">
          <h4 className={headerClassNames}>{header}</h4>
        </div>
        <div className="sui-col-start-2 sui-truncate">
          <div className={subtextClassNames}>{subtext}</div>
        </div>
      </div>
      <div className={ctaClassNames}>
        <div className={arrowClassNames}>
          <IconButton
            onClick={() => setDrawerOpen(true)}
            icon={ArrowForward}
            size="small"
            aria-label="Get Started"
          />
        </div>
        <div className={buttonClassNames}>
          <LoadingButton
            loading={isLoading}
            onClick={() => setDrawerOpen(true)}
            variant="secondary"
            fullWidth={isPlp}
          >
            Get Started
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};
