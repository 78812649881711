/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { ColorSwatchRefinement } from '../Refinements/ColorSwatchRefinement';
import { isRefinementActive } from '../../product-results-helpers';
import '../../styles/dimension.scss';

const ColorSwatchDimension = ({
  dimension,
  drawer,
  isActive,
  mini,
  onChange,
  multiFacet,
  selectedRefinements,
}) => {

  const [collapse, setCollapse] = useState(!!drawer);
  const [viewAll, setViewAll] = useState(false);

  const handleSeeAll = (event) => {
    event.preventDefault();
    setViewAll(!viewAll);
  };

  const handleCollapse = (event) => {
    event.preventDefault();
    setCollapse(!collapse);
  };

  const getRefinementId = (refinement, refinementIndex) => {
    const { refinementKey } = refinement;
    return `${refinementKey}-${refinementIndex}`;
  };

  const {
    dimensionId,
    label,
    refinements
  } = dimension;

  const isSeeAll = refinements?.length > 5;

  const dimensionClassName = classNames('dimension', {
    'dimension--mini': mini,
    'dimension--drawer': drawer,
    'dimension--padding': multiFacet
  });

  const wrapperClassName = classNames({
    'dimension--collapsed': collapse
  });

  const groupClassName = classNames('dimension__color-swatch', {
    'dimension__group--all': !collapse && viewAll,
    'dimension__group--mini': mini,
    'dimension__group--scroll': !mini,
    'dimension__group--drawer-scroll': drawer
  });

  const borderClassName = classNames('dimension__box', {
    'dimension__box--line': drawer && !collapse && !multiFacet,
    'dimension__box--padding-right': multiFacet
  });

  const dimensionLabelClassName = classNames('dimension__label', {
    'dimension__label--padding': !drawer,
    'dimension--active': isActive && drawer && multiFacet
  });

  const shadowClassName = classNames({ 'dimension__box--shadow': drawer && isSeeAll });

  const refinementClassName = classNames('dimension__item', {
    'dimension__item--hidden': !viewAll && !drawer,
    'dimension__item--fullwidth': multiFacet
  });

  return (
    <div className={dimensionClassName}>
      <Row className={shadowClassName}>
        {!mini && (
          <Row>
            <a className={borderClassName} href="#" onClick={handleCollapse}>
              <Col fallback="9">
                <h2 className={dimensionLabelClassName} data-group={label}>{label}</h2>
              </Col>
              <Col fallback="3" className="dimension__caret--align">
                <img
                  className={collapse ? 'dimension__caret--collapsed' : 'dimension__caret'}
                  alt="caret-icon"
                  src="https://assets.thdstatic.com/images/v1/caret-grey.svg"
                  height="15"
                  width="9"
                />
              </Col>
            </a>
          </Row>
        )}
        <Row className={wrapperClassName}>
          <div
            className={groupClassName}
            key={dimensionId}
            data-instoredisplay="true"
            data-testid="color-swatch-refinements"
          >
            {
              refinements && refinements
                .map((refinement, refinementIndex) => {
                  const active = isRefinementActive(refinement.refinementKey, selectedRefinements);
                  const id = getRefinementId(refinement, refinementIndex);
                  return (
                    <ColorSwatchRefinement
                      active={active}
                      dimension={dimension}
                      id={id}
                      key={id}
                      multiFacet={multiFacet}
                      onChange={onChange}
                      refinement={refinement}
                      refinementClassName={refinementClassName}
                    />
                  );
                })
            }
          </div>
          {refinements && isSeeAll && !drawer && (
            <a
              className="dimension__see-all"
              href="#"
              onClick={handleSeeAll}
            >
              {!viewAll ? '+ View All' : '- View Less'}
            </a>
          )}
        </Row>
      </Row>
    </div>
  );
};

ColorSwatchDimension.propTypes = {
  dimension: PropTypes.shape({
    dimensionId: PropTypes.string,
    label: PropTypes.string,
    refinements: PropTypes.arrayOf(
      PropTypes.shape({})
    ),
  }).isRequired,
  drawer: PropTypes.bool,
  isActive: PropTypes.bool,
  mini: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDisplayData: PropTypes.shape({
    onDisplay: PropTypes.string,
    onDisplayChange: PropTypes.func,
    isStoreDisplay: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    itemCount: PropTypes.string
  }),
  multiFacet: PropTypes.bool,
  selectedRefinements: PropTypes.arrayOf(PropTypes.shape({})),
};

ColorSwatchDimension.defaultProps = {
  drawer: false,
  isActive: false,
  mini: false,
  onDisplayData: {
    onDisplay: 'false',
    onDisplayChange: null,
    isStoreDisplay: 'false',
    itemCount: null
  },
  multiFacet: false,
  selectedRefinements: null,
};

ColorSwatchDimension.displayName = 'ColorSwatchDimension';

export { ColorSwatchDimension };
