import React from 'react';
import propType from 'prop-types';
import classNames from 'classnames/bind';
import {
  useDataModel,
  params,
  string,
  bool,
  arrayOf,
  client,
  shape, number
} from '@thd-nucleus/data-sources';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import Quantity from '../Quantity';
import styles from './ProductQuantity.module.scss';

const MERCHANDISE = 'MERCHANDISE';
const HOME_DEPOT_QUOTE_CENTER = 'HDQC';
const cx = classNames.bind(styles);

export const ProductQuantity = ({
  itemId,
  onQuantityChange,
  quantity,
  showQtyText,
  textOnly,
  wideView,
  shortHeight,
  smallText,
  noRightMargin,
  showQtyValueText,
  showQtyValueTextWide,
  mediumWidth,
  noMargin,
  hideIfATCDisabled,
  hideForGenericProduct,
  hideForNonMerchandise,
  hideForHDQCSku
}) => {
  const { data, loading, error } = useDataModel('product', {
    variables: {
      itemId
    }
  });
  const wrapperClassNames = cx(
    'quantity-box__wrapper',
    {
      'quantity-box__medium-width': mediumWidth,
      'quantity-box__no-margin': noMargin
    }
  );

  const inputWrapperClassNames = cx(
    'quantity-box__input-wrapper',
    {
      'quantity-box__input-medium-width': mediumWidth
    }
  );

  const quantityValueTextClassNames = cx(
    {
      'quantity-box__text-quantity--wide': showQtyValueTextWide
    }
  );

  const quantityTextClassNames = cx(
    'quantity-box-text',
    {
      'quantity-box-text--wide': showQtyValueTextWide
    }
  );

  const { product } = data || {};
  const { identifiers, info } = product || {};
  const { productType } = identifiers || {};
  let showQuantityBox = false;
  if (product) {
    showQuantityBox = !(
      (hideIfATCDisabled && !podFulFillmentUtils.isATCEnabled(product))
      || (hideForGenericProduct && !!(product?.info?.isGenericProduct))
      || (hideForNonMerchandise && productType !== MERCHANDISE)
      || (hideForHDQCSku && info?.productSubType?.name === HOME_DEPOT_QUOTE_CENTER)
    );
  }
  if (!showQuantityBox) {
    return null;
  }

  return ((showQtyText ? (
    <div
      data-testid="quantity-box-wrapper"
      className={wrapperClassNames}
      data-component="ProductQuantity"
    >
      <div className={quantityTextClassNames}>
        <span>Qty: </span>
        {showQtyValueText && (<span className={quantityValueTextClassNames}>{ quantity }</span>)}
      </div>
      {
        !showQtyValueText && (
          <div className={inputWrapperClassNames}>
            <Quantity
              value={quantity}
              onChange={onQuantityChange}
              textOnly={textOnly}
              wideView={wideView}
              smallText={smallText}
              shortHeight={shortHeight}
              noRightMargin={noRightMargin}
            />
          </div>
        )
      }
    </div>
  ) : (
    <div data-testid="quantity-box-wrapper" data-component="ProductQuantity">
      <Quantity
        value={quantity}
        onChange={onQuantityChange}
        shortHeight={shortHeight}
        smallText={smallText}
        textOnly={textOnly}
        noRightMargin={noRightMargin}
        wideView={wideView}
      />
    </div>
  ))
  );
};

ProductQuantity.propTypes = {
  itemId: propType.string.isRequired,
  hideIfATCDisabled: propType.bool,
  hideForGenericProduct: propType.bool,
  hideForNonMerchandise: propType.bool,
  hideForHDQCSku: propType.bool,
  mediumWidth: propType.bool,
  noRightMargin: propType.bool,
  noMargin: propType.bool,
  onQuantityChange: propType.func,
  quantity: propType.number,
  showQtyText: propType.bool,
  showQtyValueText: propType.bool,
  showQtyValueTextWide: propType.bool,
  shortHeight: propType.bool,
  smallText: propType.bool,
  textOnly: propType.bool,
  wideView: propType.bool
};

ProductQuantity.defaultProps = {
  hideIfATCDisabled: true,
  hideForGenericProduct: true,
  hideForNonMerchandise: true,
  hideForHDQCSku: true,
  onQuantityChange: () => {},
  quantity: 1,
  showQtyText: false,
  showQtyValueText: false,
  showQtyValueTextWide: false,
  textOnly: false,
  wideView: false,
  shortHeight: false,
  smallText: false,
  noRightMargin: false,
  mediumWidth: false,
  noMargin: false
};

ProductQuantity.displayName = 'ProductQuantity';

ProductQuantity.dataModel = {
  product: params({
    itemId: string().isRequired()
  }).shape({
    itemId: string(),
    dataSources: string(),
    info: shape({
      isGenericProduct: bool(),
      hidePrice: bool(),
      productSubType: shape({
        name: string()
      })
    }),
    identifiers: shape({
      productType: string()
    }),
    fulfillment: client(params({ storeId: string(), zipCode: string() }).shape({
      fulfillmentOptions: arrayOf(shape({
        type: string(),
        fulfillable: bool(),
        services: arrayOf(shape({
          type: string(),
          locations: arrayOf(shape({
            isAnchor: bool(),
            state: string(),
            inventory: shape({
              isInStock: bool(),
              isOutOfStock: bool(),
              isLimitedQuantity: bool(),
              quantity: number()
            })
          }))
        }))
      })),
      bossExcludedShipStates: string(),
      excludedShipStates: string()
    })),
    availabilityType: shape({
      type: string(),
      discontinued: bool()
    })
  })
};
