/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from 'react';
import { Radio } from '@thd-olt-component-react/checkbox';
import { func, bool } from 'prop-types';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { ProductResultsContext } from './ProductResultsContext';
import '../styles/results-sortby.scss';
import prZipCodes from '../maPuertoRicoZipCodes';

const browseSortTypes = [
  {
    name: 'Top Sellers',
    sortby: 'topsellers',
    sortorder: 'desc'
  },
  {
    name: 'Most Popular',
    sortby: 'mostpopular',
    sortorder: 'asc'
  },
  {
    name: 'Price Low to High',
    sortby: 'price',
    sortorder: 'asc'
  },
  {
    name: 'Price High to Low',
    sortby: 'price',
    sortorder: 'desc'
  },
  {
    name: 'Top Rated Products',
    sortby: 'toprated',
    sortorder: 'asc'
  }
];

const searchSortTypes = [
  {
    name: 'Best Match',
    sortby: 'bestmatch',
    sortorder: 'asc'
  },
  {
    name: 'Top Sellers',
    sortby: 'topsellers',
    sortorder: 'desc'
  },
  {
    name: 'Price Low to High',
    sortby: 'price',
    sortorder: 'asc'
  },
  {
    name: 'Price High to Low',
    sortby: 'price',
    sortorder: 'desc'
  },
  {
    name: 'Top Rated Products',
    sortby: 'toprated',
    sortorder: 'asc'
  }
];

const ResultsSortBy = ({ drawer, onSortChange }) => {

  const isSortByDeliveryDateEnabled = useConfigService('fs-prop:sort-by-delivery-date-enabled') || false;
  const [currentSort, setCurrentSort] = useState(null);
  const [collapsed, setCollapsed] = useState(true);
  const { data } = useContext(ProductResultsContext);
  const isMajorAppliance = data?.searchModel?.products?.[0]?.identifiers?.productType === 'MAJOR_APPLIANCE';
  const { deliveryZip } = useContext(ExperienceContext);
  const isZipCodeSuppressed = prZipCodes[Number(deliveryZip)] !== undefined;
  const showSoonestAvailable = isMajorAppliance && !isZipCodeSuppressed;

  const soonestAvailableSortType = {
    name: 'Soonest Available',
    sortby: 'deliverydate',
    sortorder: 'asc'
  };

  useEffect(() => {
    if (showSoonestAvailable && isSortByDeliveryDateEnabled) {
      browseSortTypes.splice(1, 0, soonestAvailableSortType);
    }

  }, [showSoonestAvailable, browseSortTypes, isSortByDeliveryDateEnabled]);

  if (!data) return null;

  const { searchReport } = data?.searchModel || {};

  if (!searchReport) return null;

  const {
    startIndex = 0,
    pageSize = 0,
    keyword = '',
    sortBy = '',
    sortOrder = ''
  } = searchReport;

  const currentPage = (startIndex && pageSize) ? Math.round(startIndex / pageSize) + 1 : 1;
  const sortTypes = keyword ? searchSortTypes : browseSortTypes;
  const pageType = keyword ? 'search' : 'browse';

  const getSortNameValue = ({ key, order }) => {
    let sortByDropdown = sortTypes.find((item) => item.sortby === key) || {};
    if (key === 'price') {
      sortByDropdown = sortTypes
        .find((item) => item.sortby === key && item.sortorder === order) || {};
    }
    const { name, sortby, sortorder } = sortByDropdown;
    return {
      name: name || 'Best Match',
      value: `${sortby}:${sortorder}`
    };
  };

  const handleSortClick = (evt) => {
    const text = evt.currentTarget.textContent || evt.currentTarget.innerText;
    const hasCategory = data?.searchModel?.dimensions?.find((dim) => dim.label === 'Category');
    setCurrentSort(text);
    sortTypes.forEach((type) => {
      if (type.name === text) {
        onSortChange({
          sortby: type.sortby,
          sortorder: type.sortorder,
          name: type.name,
          currentPage,
          page: pageType
        });
      }
    });
  };

  const handleRadioChange = (event) => {
    if (event?.target?.value) {
      const [sortby, sortorder] = event.target.value.split(':');
      const sortType = sortTypes.find((type) => type.sortby === sortby && type.sortorder === sortorder);
      onSortChange({
        sortby,
        sortorder,
        page: pageType,
        currentPage,
        name: sortType?.name || sortTypes[0]?.name
      });
    }
  };

  const sortName = getSortNameValue({
    key: (sortBy && sortBy.toLowerCase()) || '',
    order: (sortOrder && sortOrder.toLowerCase()) || ''
  }).name;

  const sortValue = getSortNameValue({
    key: (sortBy && sortBy.toLowerCase()) || '',
    order: (sortOrder && sortOrder.toLowerCase()) || ''
  }).value;

  const generateSortByRadios = (radioButtons) => radioButtons.map((radioButton, index) => {
    const { name, sortby, sortorder } = radioButton;
    const isChecked = sortValue === `${sortby}:${sortorder}`;
    return (
      <div className="sort-by-radio" key={index}>
        <input
          checked={isChecked}
          className="sort-by-radio__input"
          id={`${sortby}-${sortorder}-${index}`}
          name="sort-by-radio"
          type="radio"
          value={`${sortby}:${sortorder}`}
          onChange={handleRadioChange}
        />
        <label className="sort-by-radio__label" htmlFor={`${sortby}-${sortorder}-${index}`}>
          {name}
        </label>
      </div>
    );
  });

  const handleSortByClick = (event) => {
    event.preventDefault();
    setCollapsed(!collapsed);
  };

  return (
    <>
      {!drawer && (
        <div className={currentSort === '' ? 'drop-down u--hide' : 'drop-down'} data-component="ResultsSortBy">
          <nav key="sort-by" className="drop-down__header results-sortby__header">
            <span className="drop-down__title results-sortby__title">
              Sort by:
              <span className="results-sortby__title--bold" data-testid="current-sort">
                {getSortNameValue({
                  key: (sortBy && sortBy.toLowerCase()) || '',
                  order: (sortOrder && sortOrder.toLowerCase()) || ''
                }).name}
              </span>
            </span>
            <div className="drop-down__content-wrapper">
              {sortTypes.map((dropdown, index) => {
                return (
                  <span
                    id={index}
                    key={index}
                    role="link"
                    tabIndex={index}
                    className="drop-down__hover-effect"
                    onClick={handleSortClick}
                  >
                    <a
                      id={index}
                      key={index}
                      href="#"
                      onClick={(evt) => evt.preventDefault()}
                      className="results-sortby__link"
                      data-value={dropdown.sortby}
                      data-testid="sort-type"
                    >
                      {dropdown.name}
                    </a>
                  </span>
                );
              })}
            </div>
          </nav>
        </div>
      )}
      {drawer && (
        <>
          <div className="sort-by" data-component="ResultsSortByDrawer">
            <a className="sort-by__header" href="#" onClick={handleSortByClick}>
              <div className="sort-by__label">Sort by:</div>
              <div className="sort-by__name">
                <span className="sort-by__name--align">{sortName}</span>
                <img
                  className={collapsed ? 'dimension__caret--collapsed' : 'dimension__caret'}
                  alt="caret-icon"
                  src="https://assets.thdstatic.com/images/v1/caret-grey.svg"
                  height="15"
                  width="9"
                />
              </div>
            </a>
          </div>
          {!collapsed && (
            <div className="sort-by__items">
              <Radio.Group>{generateSortByRadios(sortTypes)}</Radio.Group>
            </div>
          )}
        </>
      )}
    </>
  );
};

ResultsSortBy.propTypes = {
  onSortChange: func.isRequired,
  drawer: bool,
};

ResultsSortBy.defaultProps = {
  drawer: false,
};

ResultsSortBy.displayName = 'ResultsSortBy';

export { ResultsSortBy };
