import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames';
import { Input } from '@thd-olt-component-react/input';
import styles from '../../styles/refinement.scss';
import {
  getRefinementLabel,
  getRefinementRangeAndUrl,
  getRefinementUnit,
  hasComparator,
  parseLabel,
  sortRefinements
} from '../../product-results-helpers';

const cx = classNames.bind(styles);
const NumericRangeRefinement = (props) => {
  const [lowerBoundRefinement, setLowerBoundRefinement] = useState('');
  const [upperBoundRefinement, setUpperBoundRefinement] = useState('');
  const [lowerBoundFocused, setLowerBoundFocused] = useState(false);
  const [upperBoundFocused, setUpperBoundFocused] = useState(false);
  const [sortedRefinements, setSortedRefinements] = useState([]);

  const {
    dimension,
    drawer,
    metadata,
    multiFacet,
    onChange,
    isMobile,
  } = props;

  const isDisabled = !(lowerBoundRefinement.length || upperBoundRefinement.length);

  const clearInputFields = () => {
    setLowerBoundRefinement('');
    setUpperBoundRefinement('');
  };

  const getLowerBound = (label) => {
    return label.split(' ')[0];
  };

  const getUpperBound = (label) => {
    return label.split(' ').slice(-1)[0];
  };

  const handleClick = useCallback((event) => {
    event.preventDefault();
    if (!isDisabled) {

      // default upper and lowerbound values to the lower and upper ranges of refinement label when a user did not
      // input a custom value
      const lowerBoundValue = lowerBoundRefinement
        ? parseLabel(getLowerBound(lowerBoundRefinement))
        : 0;

      const upperBoundValue = upperBoundRefinement
        ? parseLabel(upperBoundRefinement)
        : parseLabel(getUpperBound(getRefinementLabel(sortedRefinements.slice(-1)[0]))) + 1;

      setLowerBoundFocused(false);
      setUpperBoundFocused(false);

      const { refinements, url } = getRefinementRangeAndUrl({
        refinements: dimension.refinements,
        drawer,
        isMobile,
        lowerBound: lowerBoundValue,
        metadata,
        multiFacet,
        upperBound: upperBoundValue,
      });

      let refinementUrl;
      if (refinements.every((el) => typeof el === 'string')) {
        // get separate url paths
        let paramsArr = url.split('/');
        // find index of path with nValue
        const index = paramsArr.findIndex((el) => el.includes('N-5yc1v'));
        // modify nValue
        const [navParam, ...keys] = paramsArr[index].split('?');
        let nValue = navParam + refinements.join('');
        // append keys (ie. lowerBound/upperBound) if present
        nValue = keys.length > 0 ? nValue + '?' + keys : nValue;
        paramsArr[index] = nValue;
        // create new url
        refinementUrl = paramsArr.join('/');
      } else {
        refinementUrl = url;
      }

      if (onChange) {
        onChange({
          refinement: {
            url: refinementUrl,
            deselectUrl: '?'
          },
          dimension: {
            dimensionId: 'custom_refinement',
            label: dimension.label
          },
          refinementRange: refinements
        }
        );
      }

      clearInputFields();

    }
  }, [
    lowerBoundRefinement,
    upperBoundRefinement,
    isDisabled,
    onChange,
    drawer,
    isMobile,
    metadata,
    multiFacet,
    dimension,
  ]
  );

  const handleOnFocusFirstInput = useCallback((event) => {
    setLowerBoundFocused(true);
    setUpperBoundFocused(false);
  }, []);

  const handleOnFocusSecondInput = useCallback((event) => {
    setLowerBoundFocused(false);
    setUpperBoundFocused(true);
  }, []);

  const handleOnBlur = useCallback((event) => {
    setLowerBoundFocused(false);
    setUpperBoundFocused(false);
  }, []);

  const cleanNumericRefinement = (label) => {
    return label.split(' ')[0].replace(/[^\d.]/g, '');
  };

  const handleLowerBoundDropdownClick = useCallback((event) => {
    const lowerbound = cleanNumericRefinement(event.target.innerText);
    setLowerBoundRefinement(lowerbound);
    handleOnFocusSecondInput(event);
  }, [upperBoundFocused]);

  const handleUpperBoundDropdownClick = useCallback((event) => {
    const upperbound = cleanNumericRefinement(event.target.innerText);
    setUpperBoundRefinement(upperbound);
    handleOnBlur(event);
  }, []);

  useEffect(() => {
    let _refinements = dimension?.refinements.filter((ref) => {
      return ref.label && !hasComparator(ref);
    });

    const _sortedRefinements = _refinements.sort(sortRefinements);
    setSortedRefinements(_sortedRefinements);
  }, []);

  /* eslint-disable jsx-a11y/anchor-is-valid */
  const lowerBoundRefinements = sortedRefinements
    .map((refinement, index) => (
      <a
        key={index}
        id="lowerBound"
        className="refinement-range__list"
        onMouseDown={handleLowerBoundDropdownClick}
        href="#"
      >
        {getLowerBound(getRefinementLabel(refinement)) + getRefinementUnit(dimension)}
      </a>
    ));

  /* eslint-disable jsx-a11y/anchor-is-valid */
  const upperBoundRefinements = sortedRefinements
    .filter((refinement) => {
      const _lowerBound = parseLabel(lowerBoundRefinement) || 0;
      return parseLabel(getUpperBound(getRefinementLabel(refinement))) > _lowerBound;
    })
    .map((refinement, index) => (
      <a
        key={index}
        id="upperBound"
        className="refinement-range__list"
        onMouseDown={handleUpperBoundDropdownClick}
        href="#"
      >
        {getUpperBound(getRefinementLabel(refinement)) + getRefinementUnit(dimension)}
      </a>
    ));

  const refinementRangeInputClassName = cx('refinement-range__input', {
    'refinement-range__input--medium': !drawer,
    'refinement-range__input--wide': drawer,
  });

  const refinementRangeApplyButton = cx({
    'refinement-range__go-bttn': !drawer
  });
  const lowerBoundContainer = cx({ 'refinement-range__form--lower-bound': drawer });
  const upperBoundContainer = cx({ 'refinement-range__form--upper-bound': drawer });

  const handleLowerBoundRefinement = (value) => {
    const lowerbound = cleanNumericRefinement((value || ''));
    setLowerBoundRefinement(lowerbound);
  };

  const handleUpperBoundRefinement = (value) => {
    const upperbound = cleanNumericRefinement((value || ''));
    setUpperBoundRefinement(upperbound);
  };

  return (
    <Col className="refinement refinement-range" nopadding={!drawer}>
      <form data-testid="numeric-range-refinement-form">
        <div className="refinement-range__form">
          <div className={lowerBoundContainer}>
            <Input
              autoComplete="off"
              className={refinementRangeInputClassName}
              placeholder="Min"
              name="numeric-filter-lowerBound"
              aria-label="numeric-filter-lowerBound"
              value={lowerBoundRefinement}
              maxLength="5"
              onChange={handleLowerBoundRefinement}
              onFocus={handleOnFocusFirstInput}
              onBlur={handleOnBlur}
            />
            {lowerBoundFocused && (
              <div className="refinement-range__container">
                <ul>{lowerBoundRefinements}</ul>
              </div>
            )}
          </div>
          <span>to</span>
          <div className={upperBoundContainer}>
            <Input
              autoComplete="off"
              className={refinementRangeInputClassName}
              placeholder="Max"
              name="numeric-filter-upperBound"
              aria-label="numeric-filter-upperBound"
              value={upperBoundRefinement}
              maxLength="5"
              onChange={handleUpperBoundRefinement}
              onFocus={handleOnFocusSecondInput}
              onBlur={handleOnBlur}
            />
            {upperBoundFocused && (
              <div className="refinement-range__container--upper-bound">
                <ul>{upperBoundRefinements}</ul>
              </div>
            )}
          </div>
          <Button
            dark={!isDisabled}
            disabled={isDisabled}
            inline
            outline
            onClick={handleClick}
            className={refinementRangeApplyButton}
            data-testid="refinement-range-bttn"
          >
            {drawer ? 'Apply' : 'Go'}
          </Button>
        </div>
      </form>
    </Col>
  );
};
NumericRangeRefinement.propTypes = {
  dimension: PropTypes.shape({
    refinements: PropTypes.arrayOf(
      PropTypes.shape({})
    ),
    label: PropTypes.string
  }),
  drawer: PropTypes.bool,
  isMobile: PropTypes.bool,
  metadata: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  multiFacet: PropTypes.bool,
};

NumericRangeRefinement.defaultProps = {
  dimension: null,
  drawer: false,
  isMobile: false,
  multiFacet: false,
};

NumericRangeRefinement.displayName = 'NumericRangeRefinement';

export { NumericRangeRefinement };