/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Row } from '@thd-olt-component-react/grid';
import { Checkbox } from '@thd-olt-component-react/checkbox';
import { ExperienceContext } from '@thd-nucleus/experience-context';

const NearbyStores = (props) => {
  const { nearByStores } = useContext(ExperienceContext);
  const [checkAll, setCheckAll] = useState(true);
  const [stores, setStores] = useState([]);

  useEffect(() => {
    setStores((props.stores || []).map((store) => {
      return {
        // we remove the leading zero from storeId until
        // API team fixes the issue of them sending store with leading zero
        checked: nearByStores?.length ? nearByStores?.indexOf(parseInt(store.storeId, 10)) > -1 : true,
        ...store
      };
    }));

  }, [props?.stores?.[0]?.storeName]);

  const { onChangeClick, multiStoreCallback, setNearbyStores } = props;

  useEffect(() => {
    // this check prevents an empty array from being returned on page refresh/entry
    if (stores && stores.length > 0 && multiStoreCallback) {
      multiStoreCallback({ stores });
    }
  }, [stores]);

  const handleCheckAll = () => {
    const newStores = stores.map((store) => {
      return {
        ...store,
        checked: !checkAll
      };
    });
    // Set the current store checked state always to be 'true'
    // Prevent 'multiStoreSelection' getting revert back to 'null'
    newStores[0].checked = true;
    setNearbyStores(!checkAll);
    setCheckAll(!checkAll);
    setStores([...newStores]);

  };

  const handleCheckboxChange = ({ storeId }) => {
    let index;

    stores.forEach((store, i) => {
      if (store.storeId === storeId) {
        index = i;
      }
    });

    const newStores = stores;

    newStores[index].checked = !stores[index].checked;

    if (checkAll && newStores.filter((store) => store.checked).length === 1) {
      setCheckAll(false);
      setNearbyStores(false);
    }

    if (!checkAll && newStores.filter((store) => store.checked).length > 1) {
      setCheckAll(true);
      setNearbyStores(true);
    }

    setStores([...newStores]);
  };

  if (!stores) return null;
  if (stores.length === 0) return null;

  const anchorStore = stores[0];

  return (
    <div className="nearby-stores">
      <div className="nearby-stores__header">
        <Row>
          <p className="nearby-stores__title">Your preferred store for <b>{anchorStore?.address?.postalCode}</b>:</p>
        </Row>
        <Row className="nearby-stores__store">
          <span className="nearby-stores__store-name">{anchorStore?.storeName}</span>
          <span>&nbsp; | &nbsp;</span>
          <a className="nearby-stores__change-store u__default-link" onClick={onChangeClick}> Change</a>{/* eslint-disable-line */}
        </Row>
      </div>
      <div className="nearby-stores__separator" />
      <Checkbox.Group className="nearby-stores__checkbox-group">
        {stores
        && stores.length > 0
        && (
          stores.map((store, index) => {
            if (index === 0) {
              return (
                <Checkbox
                  className="nearby-stores__checkbox"
                  id="all-stores"
                  key="all-stores"
                  checked={checkAll}
                  onChange={handleCheckAll}
                  value="all-stores"
                >
                  Check nearby stores
                </Checkbox>
              );
            }

            const { storeId, storeName, checked, distance } = store;
            const miles = distance ? (Math.round(distance * 100) / 100) : '0.0';
            const label = `${storeName} (${miles} mi)`;
            return (
              <Checkbox
                className="nearby-stores__checkbox"
                key={storeId}
                id={storeId}
                onChange={() => handleCheckboxChange(store)}
                value={storeId}
                checked={checked}
              >
                {label}
              </Checkbox>
            );
          })
        )}
      </Checkbox.Group>
    </div>
  );
};

NearbyStores.propTypes = {
  onChangeClick: PropTypes.func.isRequired,
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      storeId: PropTypes.string,
      storeName: PropTypes.string,
      phone: PropTypes.string,
      address: PropTypes.shape({
        postalCode: PropTypes.string
      })
    })
  ).isRequired,
  multiStoreCallback: PropTypes.func,
  setNearbyStores: PropTypes.func
};

NearbyStores.defaultProps = {
  multiStoreCallback: () => {},
  setNearbyStores: () => {},
};

NearbyStores.displayName = 'NearbyStores';

export { NearbyStores };
